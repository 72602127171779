<template>
  <div class="form">
    <div class="form__header row" :class="{ 'form__header--sticky': isSticky }">
      <div class="form__header__back col-md-2 col-xs-10 offset-xs-1 offset-md-0">
        <router-link v-bind:to="cancelUrl" class="form__header__back__link form__header__back__link--arrow">{{
          $t('goBack')
        }}</router-link>
      </div>
      <div class="form__header__main col-12 col-xs-10 col-md-4 col-xl-5 offset-xs-1 offset-md-0">
        <h1 class="form__header__title">{{ $t('payProjectFees') }}</h1>
      </div>
    </div>
    <div class="form__body row">
      <div class="form__container col-xs-10 col-md-8 offset-xs-1 offset-md-2">
        <div class="o-payproject">
          <div class="o-payproject__amount">
            <span class="o-payproject__amount__label">{{ $t('amountToPayProject') }}</span> {{ amountToPay }}
          </div>

          <div class="o-payproject__grid">
            <div
              class="o-payproject__row"
              v-for="invoice in invoices"
              v-bind:key="invoice.id"
              v-on:click="followLink(invoice.downloadUrl)"
            >
              <div class="o-payproject__col">{{ invoice.number }}</div>
              <div class="o-payproject__col">
                <strong class="o-payproject__grid__amount">{{ invoice.balance }}</strong>
              </div>
              <div class="o-payproject__col">{{ $t('paymentCode') }} {{ invoice.paymentCode }}</div>
              <div class="o-payproject__col">{{ invoice.days }}</div>
              <div class="o-payproject__col o-payproject__col--download">
                <button class="btn btn-download" v-tooltip="$t('download')"></button>
              </div>
            </div>
          </div>

          <h3 class="o-payproject__subtitle">{{ $t('payProjectPayFinancialInstitution') }}</h3>
          <div class="row">
            <div class="col-6">
              <div class="o-payproject__logobox">
                <img
                  class="o-payproject__logobox__img"
                  src="../../assets/desjardins.png"
                  width="180"
                  height="48"
                  alt="Desjardins"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="o-payproject__logobox">
                <img
                  class="o-payproject__logobox__img"
                  src="../../assets/banque_nationale.png"
                  width="165"
                  height="53"
                  alt="Banque Nationale"
                />
              </div>
            </div>
          </div>
          <h3 class="o-payproject__subtitle">{{ $t('payProjectPayByCheck') }}</h3>
          <ul>
            <li>{{ $t('payProjectPayTo') }}</li>
            <li>
              {{ $t('payProjectSendCheckAddress') }}<br />
              <span class="o-payproject__address">
                {{ $t('payProjectAddressLine1') }}<br />
                {{ $t('payProjectAddressLine2') }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Urls, Consts, Cash, Dates } from '@/_helpers'
import form from '@/components/mixins/form'
import getProjectInvoices from '@/graphql/accreditations/projects/getProjectInvoices.graphql'

require('../../utilities/table')
require('../../utilities/button')

export default {
  mixins: [form],
  data() {
    return {
      cancelUrl: `${Urls.localizedUrl(Urls.PROJECT_DETAILS)}?id=${this.$route.query.id}`,
      invoices: [],
      amountToPay: ''
    }
  },
  apollo: {
    getoneproject: {
      query: getProjectInvoices,
      variables() {
        return {
          accredid: this.$store.getters[Consts.GETTER_ACCREDITATION_CURRENT_ID],
          projectid: this.$route.query.id,
          invoiceFilter: { unpaidOnly: true }
        }
      },
      update(data) {
        this.amountToPay = Cash.format(data.accreditation.project.invoices.items.reduce((pv, cv) => pv + cv['balance'], 0))

        this.invoices = data.accreditation.project.invoices.items.map(inv => ({
          id: inv.id,
          balance: Cash.format(inv.balance),
          number: inv.number,
          downloadUrl: `${Consts.ROOT_API}${inv.downloadUrl}`,
          days: Dates.formatDays(Dates.diffDays(inv.issueDate, new Date())),
          paymentCode: inv.paymentCode
        }))
      }
    }
  },
  methods: {
    followLink(link) {
      if (link.startsWith('/')) {
        this.$router.push(link)
      } else {
        window.location.href = link
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';
.o-payproject {
  padding: 40px;
  border: 1px solid gray('gray-150');
  background-color: gray('white');
  &__amount {
    font-size: 30px;
    font-weight: 500;
    color: gray('gray-400');
    line-height: 26px;
    margin-bottom: 24px;
    &__label {
      display: block;
      font-size: 17px;
      color: gray('gray-700');
    }
  }
  &__grid {
    margin-bottom: 30px;
    border: 1px solid gray('gray-150');
    border-bottom: 0;
    &__amount {
      font-size: 16px;
    }
  }
  &__row {
    @media screen and (min-width: breakpoint-min('md')) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.75rem 0;
    }
    border-bottom: 1px solid gray('gray-150');
    cursor: pointer;
    position: relative;
    padding: 0.75rem 60px 0.75rem 0;
  }
  &__col {
    padding: 0 0.75rem;
    font-size: 14px;

    &--download {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      @media screen and (min-width: breakpoint-min('md')) {
        position: relative;
        transform: translateY(0%);
      }
    }
  }
  &__logobox {
    border: 1px solid gray('gray-150');
    background-color: gray('white');
    padding: 15px;
    text-align: center;
    @media screen and (min-width: breakpoint-min('md')) {
      min-height: 120px;
    }
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__img {
      max-width: 100%;
      height: auto;
    }
  }
  &__subtitle {
    font-size: 17px;
    color: gray('gray-700');
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  &__address {
    margin-top: 8px;
    margin-left: 15px;
    font-style: italic;
    display: inline-block;
  }
}
</style>
