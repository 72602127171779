<template>
  <div><pay-project></pay-project></div>
</template>

<script>
import PayProject from '@/components/organism/PayProject'

export default {
  components: { PayProject }
}
</script>
